import React from "react";

const experienceContent = [
  {
    year: "   MAY 2021 - PRESENT",
    position: "FRONT-END WEB DEVELOPER",
    compnayName: "MRARA ENTERPRISES",
    details: `
    - Implemented UI designs and front end logic using React and Redux,
    - Creating reusable, modular code and components for front-end development using Vue.js
    - Developing and maintaining web applications using Vue.js and other related technologies like Vuex, Vue Router, and Nuxt.js.`,
  },
  {
    year: "SEPTEMBER 2021 - DECEMBER 2021",
    position: "FULL-STACK WEB DEVELOPER",
    compnayName: "MEREB TECHNOLOGIES",
    details: `Worked on different projects using programming languages like Javascript and Go`,
  },
  {
    year: "JANUARY 2018 - AUGUST 2021",
    position: "FULL-STACK WEB DEVELOPER",
    compnayName: "TILTEK TECHNOLOGIES",
    details: `Designed and built web-based applications using Node.js, React.js, Vue.js and Angular`,
  },
  {
    year: "JUNE 2017 - JANUARY 2018",
    position: "FRONT-END WEB DEVELOPER",
    compnayName: "360 GROUND",
    details: `Designed and built web-based applications for local businesses using React.js and Vue.js`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
