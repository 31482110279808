import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import DirectionReveal from "direction-reveal";
import ModalSixContent from "./modal/ModalSixContent";


const projects = {
  manager_special: {
    project: 'Manager Special',
    client: '',
    languages: 'Javascript, HTML, CSS',
    website_link: 'https://www.managerspecial.com/',
    website: 'www.managerspecial.com',
    image: 'img/projects/mg.png'
  },
  parabolic: {
    project: 'Parabolic',
    client: '',
    languages: 'Javascript, HTML, CSS',
    website_link: 'https://parabolic.vercel.app/',
    website: 'www.parabolic.vercel.app',
    image: 'img/projects/pc.png'
  },
  talguu: {
    project: 'Talguu',
    client: '',
    languages: 'Javascript, HTML, CSS',
    website_link: 'https://8mspaa.com/',
    website: 'www.8mspaa.com',
    image: 'img/projects/tg.png'
  },
  trabahanap: {
    project: 'Trabahanap',
    client: '',
    languages: 'Javascript, HTML, CSS',
    website_link: 'https://trabahanap.com/',
    website: 'www.trabahanap.com/',
    image: 'img/projects/tr.png'
  }
}

const PortfolioLight = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  function toggleModalTwo() {
    setIsOpen2(!isOpen2);
  }
  function toggleModalThree() {
    setIsOpen3(!isOpen3);
  }
  function toggleModalFour() {
    setIsOpen4(!isOpen4);
  }

  useEffect(() => {
    // Init with default setup
    DirectionReveal();

    // Init with all options at default setting
    DirectionReveal({
      selector: ".direction-reveal",
      itemSelector: ".direction-reveal__card",
      animationName: "slide",
      animationPostfixEnter: "enter",
      animationPostfixLeave: "leave",
      touchThreshold: 500,
    });
  }, []);

  return (
    <ul className="row grid">
      <li className="direction-reveal">
        <figure className="direction-reveal__card" onClick={toggleModalOne}>
          <img src="img/projects/mg.png" alt="Portolio" />
          <div className=" direction-reveal__overlay direction-reveal__anim--enter">
            <span className="direction-reveal__title">Manager Special</span>
          </div>
        </figure>

        {/* <!-- Portfolio Item Starts --> */}
        <Modal
          isOpen={isOpen}
          onRequestClose={toggleModalOne}
          contentLabel="My dialog"
          className="custom-modal dark green"
          overlayClassName="custom-overlay"
          closeTimeoutMS={500}
        >
          <div>
            <button className="close-modal" onClick={toggleModalOne}>
              <img src="/img/cancel.svg" alt="close icon" />
            </button>
            {/* End close icon */}

            <div className="box_inner portfolio">
              <ModalSixContent data={projects['manager_special']} />
            </div>
          </div>
        </Modal>
        {/* End  ModalSixContent */}
      </li>
      {/* <!-- Portfolio Item Ends --> */}

      {/* <!-- Portfolio Item Starts --> */}
      <li className="direction-reveal">
        <figure className="direction-reveal__card" onClick={toggleModalTwo}>
          <img src="img/projects/pc.png" alt="Portolio" />
          <div className=" direction-reveal__overlay direction-reveal__anim--enter">
            <span className="direction-reveal__title">Parabolic</span>
          </div>
        </figure>

        {/* Start ModalSixContent */}
        <Modal
          isOpen={isOpen2}
          onRequestClose={toggleModalTwo}
          contentLabel="My dialog"
          className="custom-modal dark green"
          overlayClassName="custom-overlay"
          closeTimeoutMS={500}
        >
          <div>
            <button className="close-modal" onClick={toggleModalTwo}>
              <img src="/img/cancel.svg" alt="close icon" />
            </button>
            {/* End close icon */}

            <div className="box_inner portfolio">
              <ModalSixContent data={projects['parabolic']} />
            </div>
          </div>
        </Modal>
        {/* End  ModalSixContent */}
      </li>
      {/* <!-- Portfolio Item Ends --> */}

      {/* <!-- Portfolio Item Starts --> */}
      <li className="direction-reveal">
        <figure className="direction-reveal__card" onClick={toggleModalThree}>
          <img src="img/projects/tg.png" alt="Portolio" />
          <div className=" direction-reveal__overlay direction-reveal__anim--enter">
            <span className="direction-reveal__title">Talguu</span>
          </div>
        </figure>

        {/* Start ModalSixContent */}
        <Modal
          isOpen={isOpen3}
          onRequestClose={toggleModalThree}
          contentLabel="My dialog"
          className="custom-modal dark green"
          overlayClassName="custom-overlay"
          closeTimeoutMS={500}
        >
          <div>
            <button className="close-modal" onClick={toggleModalThree}>
              <img src="/img/cancel.svg" alt="close icon" />
            </button>
            {/* End close icon */}

            <div className="box_inner portfolio">
              <ModalSixContent data={projects['talguu']} />
            </div>
          </div>
        </Modal>
        {/* End  ModalSixContent */}
      </li>
      {/* <!-- Portfolio Item Ends --> */}

      {/* <!-- Portfolio Item Starts --> */}
      <li className="direction-reveal">
        <figure className="direction-reveal__card" onClick={toggleModalFour}>
          <img src="img/projects/tr.png" alt="Portolio" />
          <div className="direction-reveal__overlay direction-reveal__anim--enter">
            <span className="direction-reveal__title">Trabahanap</span>
          </div>
        </figure>

        {/* Start ModalSixContent */}
        <Modal
          isOpen={isOpen4}
          onRequestClose={toggleModalFour}
          contentLabel="My dialog"
          className="custom-modal dark green"
          overlayClassName="custom-overlay"
          closeTimeoutMS={500}
        >
          <div>
            <button className="close-modal" onClick={toggleModalFour}>
              <img src="/img/cancel.svg" alt="close icon" />
            </button>
            {/* End close icon */}

            <div className="box_inner portfolio">
              <ModalSixContent data={projects['trabahanap']} />
            </div>
          </div>
        </Modal>
        {/* End  ModalSixContent */}
      </li>
      {/* <!-- Portfolio Item Ends --> */}
    </ul>
  );
};

export default PortfolioLight;
